<template>
  <div>
    
    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="modal">
      
      <h4 class="text-xl font-bold mb-16">Add Expense Category</h4>

      <form @submit.prevent="submit">

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-16">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          left-icon="albums-outline"
          name="label"
          :form="form"
          v-model="form.data.label.value"
        >
          Expense Category Label
        </form-group>

        <div class="text-center">
          <button type="submit" class="btn btn-blue btn-md" :disabled="form.loading">
            <span v-if="form.loading">Saving</span>
            <span v-else>Save Category</span>
          </button>
        </div>

      </form>

    </modal>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        categories: this.$options.resource([]),
        form: this.$options.basicForm([
          'label'
        ]),
      }
    },
    methods: {
      close() {
        this.$refs.modal.close();
      },
      open() {
        this.$refs.modal.open();
      },
      async submit() {
        this.form.loading = true;

        await this.sendRequest('corporate.expenseCategories.new', {
          data: {
            label: this.form.data.label.value,
            slug: this.form.data.label.value.replace(' ', '_').toLowerCase(),
            company_id: this.user.company_id,
          },
          success: response => {
            this.form = this.resetForm(this.form);
            this.$emit('success', response.data.data);
            this.close();
          },
          error: error => {
            this.form.error =  error;
            this.mapFormError(this.form, error.response?.data?.errors);
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>